.swal2-container {
  position: relative;
  z-index: 9999;
}

.swal2-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 1000;
}

.swal2-modal {
  max-width: 440px;
  background-color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -200px;
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  z-index: 2000;
}

.swal2-modal.loading {
  overflow-y: hidden;
}

.swal2-modal h2 {
  color: #575757;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  text-transform: none;
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 60px;
  display: block;
}

.swal2-modal hr {
  height: 10px;
  color: transparent;
  border: 0;
}

.swal2-modal button.styled {
  color: #fff;
  border: 0;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  padding: 10px 32px;
  margin: 0 5px;
  cursor: pointer;
}

.swal2-modal button.styled:not(.loading)[disabled] {
  opacity: 0.4;
  cursor: no-drop;
}

.swal2-modal button.styled.loading {
  box-sizing: border-box;
  border: 4px solid transparent;
  border-color: transparent;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: -2px 30px;
  vertical-align: top;
  background-color: transparent !important;
  color: transparent;
  cursor: default;
  border-radius: 100%;
  -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
  animation: rotate-loading 1.5s linear 0s infinite normal;
}

.swal2-modal button:not(.styled).loading:after {
  display: inline-block;
  content: "";
  margin-left: 5px;
  vertical-align: -1px;
  height: 6px;
  width: 6px;
  border: 3px solid #999;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
  animation: rotate-loading 1.5s linear 0s infinite normal;
}

.swal2-modal .swal2-image {
  margin: 20px auto;
  max-width: 100%;
}

.swal2-modal .swal2-close {
  font-size: 36px;
  line-height: 36px;
  font-family: serif;
  position: absolute;
  top: 5px;
  right: 13px;
  cursor: pointer;
  color: #cfcfcf;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.swal2-modal .swal2-close:hover {
  color: #d55;
}

.swal2-modal > .swal2-input,
.swal2-modal > .swal2-textarea,
.swal2-modal > .swal2-select,
.swal2-modal > .swal2-radio,
.swal2-modal > .swal2-checkbox {
  display: none;
}

.swal2-content {
  font-size: 18px;
  text-align: center;
  font-weight: 300;
  position: relative;
  float: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  color: #555;
}

.swal2-confirm {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  box-shadow: 0 2px 2px 0 rgba(23, 161, 191, 0.14),
    0 3px 1px -2px rgba(23, 161, 191, 0.2), 0 1px 5px 0 rgba(23, 161, 191, 0.12) !important;
}

.swal2-confirm:hover {
  box-shadow: 0 14px 26px -12px rgba(23, 161, 191, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 161, 191, 0.2) !important;
}

.swal2-cancel {
  background-color: rgb(170, 170, 170) !important;
  border-color: rgb(170, 170, 170) !important;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12) !important;
}

.swal2-cancel:hover {
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2) !important;
}

.swal2-icon {
  width: 60px;
  height: 60px;
  border: 4px solid #808080;
  border-radius: 50%;
  margin: 20px auto 30px;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  cursor: default;
  /* http://stackoverflow.com/a/4407335/1331425 */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.swal2-icon.swal2-error {
  border-color: #f27474;
}

.swal2-icon.swal2-error .x-mark {
  position: relative;
  display: block;
}

.swal2-icon.swal2-error .line {
  position: absolute;
  height: 5px;
  width: 28px;
  background-color: #f27474;
  display: block;
  top: 28px;
  border-radius: 2px;
}

.swal2-icon.swal2-error .line.left {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 17px;
}

.swal2-icon.swal2-error .line.right {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 16px;
}

.swal2-icon.swal2-warning {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #f8bb86;
  border-color: #f8bb86;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
}

.swal2-icon.swal2-info {
  font-family: "Open Sans", sans-serif;
  color: #3fc3ee;
  border-color: #3fc3ee;
  font-size: 60px;
  line-height: 80px;
  text-align: center;
}

.swal2-icon.swal2-question {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #c9dae1;
  border-color: #c9dae1;
  font-size: 60px;
  line-height: 80px;
  text-align: center;
}

.swal2-icon.swal2-success {
  border-color: #a5dc86;
}

.swal2-icon.swal2-success::before,
.swal2-icon.swal2-success::after {
  content: "";
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.swal2-icon.swal2-success::before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}

.swal2-icon.swal2-success::after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 60px;
  transform-origin: 0 60px;
}

.swal2-icon.swal2-success .placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(165, 220, 134, 0.2);
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
}

.swal2-icon.swal2-success .fix {
  width: 7px;
  height: 90px;
  background-color: #fff;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.swal2-icon.swal2-success .line {
  height: 5px;
  background-color: #a5dc86;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}

.swal2-icon.swal2-success .line.tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.swal2-icon.swal2-success .line.long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.swal2-input,
.swal2-textarea,
.swal2-select,
.swal2-radio,
.swal2-checkbox {
  margin: 20px auto;
}

.swal2-input:not([type="file"]),
.swal2-textarea {
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #d7d7d7;
  font-size: 18px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.06);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.swal2-input:not([type="file"]).error,
.swal2-textarea.error {
  border-color: #f06e57 !important;
}

.swal2-input:not([type="file"]):focus,
.swal2-textarea:focus {
  outline: none;
  box-shadow: 0px 0px 3px #c4e6f5;
  border: 1px solid #b4dbed;
}

.swal2-input:not([type="file"]):focus::-moz-placeholder,
.swal2-textarea:focus::-moz-placeholder {
  -webkit-transition: opacity 0.3s 0.03s ease;
  transition: opacity 0.3s 0.03s ease;
  opacity: 0.8;
}

.swal2-input:not([type="file"]):focus:-ms-input-placeholder,
.swal2-textarea:focus:-ms-input-placeholder {
  -webkit-transition: opacity 0.3s 0.03s ease;
  transition: opacity 0.3s 0.03s ease;
  opacity: 0.8;
}

.swal2-input:not([type="file"]):focus::-webkit-input-placeholder,
.swal2-textarea:focus::-webkit-input-placeholder {
  -webkit-transition: opacity 0.3s 0.03s ease;
  transition: opacity 0.3s 0.03s ease;
  opacity: 0.8;
}

.swal2-input:not([type="file"])::-moz-placeholder,
.swal2-textarea::-moz-placeholder {
  color: #bdbdbd;
}

.swal2-input:not([type="file"]):-ms-input-placeholder,
.swal2-textarea:-ms-input-placeholder {
  color: #bdbdbd;
}

.swal2-input:not([type="file"])::-webkit-input-placeholder,
.swal2-textarea::-webkit-input-placeholder {
  color: #bdbdbd;
}

.swal2-input:not([type="file"]) {
  height: 43px;
  padding: 0 12px;
}

.swal2-input[type="file"] {
  font-size: 20px;
}

.swal2-textarea {
  height: 108px;
  padding: 12px;
}

.swal2-select {
  color: #555;
  font-size: inherit;
  padding: 5px 10px;
  min-width: 40%;
  max-width: 100%;
}

.swal2-radio {
  border: 0;
}

.swal2-radio label:not(:first-child) {
  margin-left: 20px;
}

.swal2-radio input,
.swal2-radio span {
  vertical-align: middle;
}

.swal2-radio input {
  margin: 0 3px 0 0;
}

.swal2-checkbox {
  color: #555;
}

.swal2-checkbox input,
.swal2-checkbox span {
  vertical-align: middle;
}

.swal2-validationerror {
  background-color: #f1f1f1;
  margin: 0 -20px;
  overflow: hidden;
  padding: 10px;
  color: #797979;
  font-size: 16px;
  font-weight: 300;
  display: none;
}

.swal2-validationerror::before {
  content: "!";
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ea7d7d;
  color: white;
  line-height: 24px;
  text-align: center;
  margin-right: 10px;
}

@-webkit-keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}

@keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}

.show-swal2 {
  -webkit-animation: showSweetAlert 0.3s;
  animation: showSweetAlert 0.3s;
}

.show-swal2.no-animation {
  -webkit-animation: none;
  animation: none;
}

.hide-swal2 {
  -webkit-animation: hideSweetAlert 0.15s;
  animation: hideSweetAlert 0.15s;
}

.hide-swal2.no-animation {
  -webkit-animation: none;
  animation: none;
}

@-webkit-keyframes animate-success-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animate-success-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@-webkit-keyframes animate-success-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes animate-success-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@-webkit-keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

@keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

.animate-success-tip {
  -webkit-animation: animate-success-tip 0.75s;
  animation: animate-success-tip 0.75s;
}

.animate-success-long {
  -webkit-animation: animate-success-long 0.75s;
  animation: animate-success-long 0.75s;
}

.swal2-icon.swal2-success.animate::after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}

@-webkit-keyframes animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
}

.animate-error-icon {
  -webkit-animation: animate-error-icon 0.5s;
  animation: animate-error-icon 0.5s;
}

@-webkit-keyframes animate-x-mark {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    margin-top: -6px;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes animate-x-mark {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    margin-top: -6px;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

.animate-x-mark {
  -webkit-animation: animate-x-mark 0.5s;
  animation: animate-x-mark 0.5s;
}

@-webkit-keyframes pulse-warning {
  0% {
    border-color: #f8d486;
  }
  100% {
    border-color: #f8bb86;
  }
}

@keyframes pulse-warning {
  0% {
    border-color: #f8d486;
  }
  100% {
    border-color: #f8bb86;
  }
}

.pulse-warning {
  -webkit-animation: pulse-warning 0.75s infinite alternate;
  animation: pulse-warning 0.75s infinite alternate;
}

@-webkit-keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Body scroller */
/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
  min-height: 20%;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
